import React from "react"

import styles from "./styles.module.css"

const Button = ({ children, style }) => {
  return (
    <button className={styles.button} style={style}>
      {children}
    </button>
  )
}


export default Button
