import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import styles from "./styles.module.css"

import ReactMarkdown from "react-markdown"

const FeatureCard = ({ type, title, description, icon, knowMore }) => {
  return (
    <div className={`${styles.featureCard} ${styles[type]}`}>
      <img src={icon.src} alt={title} className={styles.icon} />
      <h3 className={styles.title}>{title}</h3>
      <ReactMarkdown className={styles.description} source={description} />
      {knowMore && <Link to={knowMore}>Saber mais >></Link>}
    </div>
  )
}

FeatureCard.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  knowMore: PropTypes.string,
}

FeatureCard.defaultProps = {
  type: "dark",
}

export default FeatureCard
