import React from "react"
import ReactMarkdown from "react-markdown"

import SimpleLayout from "../layouts/SimpleLayout"
import SEO from "../components/seo"
import Button from "../components/Button/Button"
import FeatureCard from "../components/FeatureCard/FeatureCard"
import Newsletter from "../components/Forms/Newsletter"

import styles from "./styles/index.module.css"

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

const IndexPageComponent = ({
  seoTitle,
  seoDescription,
  seoImage,
  headerSeparator,
  sectionSeparator,
  title,
  subtitle,
  highlightImage,
  highlightImageLink,
  businessFeatures,
  statsSection,
  brandsSections,
  isPreview,
  ...props
}) => (
  <SimpleLayout path={props.path} isPreview={isPreview}>
    <SEO title={seoTitle} description={seoDescription} image={seoImage} />
    <section className={styles.headerSection}>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{subtitle}</p>
        {highlightImage && (
          <a href={highlightImageLink}>
            <img
              src={highlightImage}
              className={styles.headerImg}
              alt={title}
            />
          </a>
        )}
        {/* <div className={styles.capp}>
                <p>Faça o download da nossa app</p>
                <Link to="/capp">
                  <img
                    className={styles.stores}
                    src={require("../img/stores.png")}
                    alt="App Store e Google Store"
                  />
                </Link>
              </div> */}
      </div>
      <img className={styles.separator1} src={headerSeparator} alt="" />
    </section>
    <section className={styles.featuresSection}>
      <div className={styles.content}>
        {(businessFeatures || []).map(feat => (
          <FeatureCard
            key={feat.title}
            title={feat.title}
            description={feat.description}
            icon={feat.icon.childImageSharp.fixed}
            type="light"
          />
        ))}
      </div>
    </section>

    <section className={styles.numbersSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>{(statsSection || {}).title}</h2>

        <div className={styles.stats}>
          {((statsSection || {}).stats || []).map((stat, i) => (
            <div className={styles.statCard} key={i}>
              <h3 className={styles.number}>{numberWithCommas(stat.number)}</h3>
              <p className={styles.description}>{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

    {(brandsSections || []).map((section, i) => (
      <section
        className={`${styles.brandsSection} ${
          i % 2 === 0 ? styles.left : styles.right
        }`}
      >
        <div className={styles.content}>
          <div className={styles.info}>
            <img
              src={section.logo.childImageSharp.fixed.src}
              alt={title}
              className={styles.logoImg}
            />

            <h4 className={styles.title}>{section.title}</h4>

            <ReactMarkdown
              className={styles.description}
              source={section.description}
            />
            {section.highlightedText && (
              <div
                className={styles.highlightedText}
                style={{ color: section.highlightedColor }}
              >
                <ReactMarkdown
                  className={styles.description}
                  source={section.highlightedText}
                />
              </div>
            )}
          </div>
          <div className={styles.image}>
            <img
              src={section.image.childImageSharp.fluid.src}
              alt={title}
              className={styles.descriptionImage}
            />
            {section.link && (
              <a href={section.link}>
                <Button>Saiba mais</Button>
              </a>
            )}
          </div>
        </div>
        {brandsSections.length !== i + 1 && (
          <img
            className={i % 2 === 0 ? styles.separator2 : styles.separator3}
            src={sectionSeparator}
            alt=""
          />
        )}
      </section>
    ))}

    <Newsletter isPreview={isPreview} />
  </SimpleLayout>
)

export default IndexPageComponent
