import React from "react"

import { StaticQuery, graphql } from "gatsby"

import IndexPageComponent from "../page-components/IndexPageComponent"

const imageSrcFormatter = (imageSrc, type = "fixed") =>
  (imageSrc &&
    imageSrc.childImageSharp &&
    imageSrc.childImageSharp[type] &&
    imageSrc.childImageSharp[type].src) ||
  ""
  
const indexQuery = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/pages/home/" } }) {
      frontmatter {
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        title
        subtitle
        highlightImage {
          childImageSharp {
            fixed(width: 600, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        highlightImageLink
        headerSeparator {
          childImageSharp {
            fixed(width: 1500, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        sectionSeparator {
          childImageSharp {
            fixed(width: 1500, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        businessFeatures {
          title
          description
          icon {
            childImageSharp {
              fixed(height: 85, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        statsSection {
          title
          stats {
            number
            description
          }
        }
        brandsSections {
          title
          description
          link
          highlightedText
          highlightedColor
          image {
            childImageSharp {
              fluid(maxHeight: 500, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logo {
            childImageSharp {
              fixed(height: 50, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
const IndexPage = () => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      const {
        seoTitle,
        seoDescription,
        seoImage,
        headerSeparator,
        sectionSeparator,
        title,
        subtitle,
        highlightImage,
        highlightImageLink,
        businessFeatures,
        statsSection,
        brandsSections,
      } = data.markdownRemark.frontmatter

      return (
        <IndexPageComponent
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={imageSrcFormatter(seoImage)}
          headerSeparator={imageSrcFormatter(headerSeparator)}
          sectionSeparator={imageSrcFormatter(sectionSeparator)}
          title={title}
          subtitle={subtitle}
          highlightImage={imageSrcFormatter(highlightImage)}
          businessFeatures={businessFeatures}
          statsSection={statsSection}
          brandsSections={brandsSections}
          highlightImageLink={highlightImageLink}
        />
      )
    }}
  />
)

export default IndexPage
